import React from 'react'
import Image from "next/legacy/image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { styled } from '../../../../stitches.config'
import { Text } from '../../elements/Text/Text'

export interface PressCoverageProps {
    imageUrl: string
    showImage: boolean
    showIcon: boolean
    pressUrl: string
    pressTitle: string
    pressTitleColor: '$primary' | '$secondary'
    pressDate: string
    pressDateColor: '$mediumGray' | '$darkest'
}

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem',
    alignItems: 'flex-start',
})

const PressCoverage = ({
    imageUrl,
    showImage,
    showIcon,
    pressUrl,
    pressDate,
    pressTitle,
    pressTitleColor,
    pressDateColor,
}: PressCoverageProps) => {
    return (
        <Wrapper>
            {showImage && (
                <a href={pressUrl} rel={'noopener noreferrer'} target="_blank">
                    <Image
                        src={imageUrl}
                        height={44}
                        width={226}
                        alt={'Press Logo'}
                        style={{ alignSelf: 'start' }}
                    />
                </a>
            )}
            <Text
                as={'p'}
                size={2}
                css={{
                    fontWeight: 600,
                    lineHeight: '125%',
                    color: pressDateColor,
                    textTransform: 'uppercase',
                    margin: showImage ? '2.25rem 0 .75rem' : '0 0 .75rem',
                }}
            >
                {pressDate}
            </Text>
            <a href={pressUrl} rel={'noopener noreferrer'} target="_blank">
                <Text
                    as={'p'}
                    size={6}
                    css={{
                        fontWeight: 600,
                        lineHeight: '125%',
                        letterSpacing: '.05rem',
                        color: pressTitleColor,
                        margin: '.5rem 0 1.15rem',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {pressTitle}
                </Text>
            </a>
            {showIcon && (
                <span
                    style={{
                        display: 'block',
                        alignSelf: 'start',
                        fontSize: '1.4rem',
                    }}
                >
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                            color: pressTitleColor,
                            cursor: 'pointer',
                        }}
                    />
                </span>
            )}
        </Wrapper>
    )
}

export default PressCoverage
